*{
  margin: 0;
  padding: 0;
}

h1{
  text-align: center;
  margin-top: 30px;
}

th{
  border: 1px solid rgb(255, 255, 255);
  background-color: rgb(85, 117, 165);
  padding-left: 25px;
  padding-top: 25px;
  padding-bottom: 25px;
  padding-right: 25px;
}

td{
  border: 1px solid rgb(255, 255, 255);
  padding-right: 20px;
}

table{
  border: 1px solid rgb(255, 255, 255);
  border-collapse: collapse;
  margin-top: 10px;
}

.attribut{
  font-size: 30px;
}
.TABLEcontainer{
  display: flex;
  flex-direction: column;
  align-items: center;
  font-size: 20px;
}

.tabellecss td{
  padding-right: 75px;
}

.tabellecss{
  margin-bottom: 75px;
}